export default [
	[[100, 0], [-50, 86.60254038], [-50, -86.60254038]],
	[[-100.63992436, 19.77373916],
	[33.19540174, -97.04360071],
	[67.44452262, 77.26986155]],
	[[97.31307743, -39.77567692],
	[-14.20979205, 104.16343564],
	[-83.10328538, -64.38775872]],
	[[-89.96082663, 59.20036155],
	[-6.2886037, -107.50854198],
	[96.24943033, 48.30818044]],
	[[78.68961426, -77.22966147],
	[27.53804163, 106.7620357],
	[-106.22765589, -29.53237423]],
	[[-63.7733207, 93.06681299],
	[-48.71156395, -101.7627223],
	[112.48488465, 8.69590931]],
	[[45.64857993, -105.97082909],
	[68.94914009, 92.51824441],
	[-114.59772002, 13.45258468]],
	[[-24.90301331, 115.28980872],
	[-87.39239649, -79.21154652],
	[112.2954098, -36.0782622]],
	[[2.25664984, -120.49169033],
	[103.22053985, 62.20016126],
	[-105.47718969, 58.29152908]],
	[[21.46290007, 121.19105955],
	[-115.68598631, -42.00811308],
	[94.22308625, -79.18294647]],
	[[-45.35066797, -117.17074822],
	[124.14817852, 19.31054357],
	[-78.79751056, 97.86020465]],
	[[68.45884506, 108.39714679],
	[-128.10410534, 5.08852554],
	[59.64526028, -113.48567233]],
	[[-89.8342779, -95.02838644],
	[127.21413569, -30.28457357],
	[-37.37985779, 125.31296001]],
	[[108.55746274, 77.41482456],
	[-121.32193607, 55.30610822],
	[12.76447333, -132.72093278]],
	[[-123.78145415, -56.09157416],
	[110.46745524, -79.15209673],
	[13.31399891, 135.24367089]],
	[[134.76906269, 31.76314493],
	[-94.89222175, 100.83185947],
	[-39.87684093, -132.59500439]],
	[[-140.92674247, -5.28059487],
	[75.03650054, -119.40584162],
	[65.89024193, 124.68643649]],
	[[141.83366362, -22.38808451],
	[-51.52818188, 134.02559806],
	[-90.30548174, -111.63751355]],
	[[-137.26462338, 50.19332541],
	[25.16361679, -143.97131359],
	[112.10100659, 93.77798818]],
	[[127.20566919, -77.04379272],
	[3.11904711, 148.68523738],
	[-130.3247163, -71.64144466]],
	[[-111.86157832, 101.84913517],
	[-32.27314925, -147.79953612],
	[144.13472757, 45.95040095]],
	[[91.65465239, -123.56400669],
	[61.18224259, 141.15726069],
	[-152.83689498, -17.593254]],
	[[-67.21462891, 141.23158985],
	[-88.70283017, -128.82537106],
	[155.91745908, -12.40621878]],
	[[39.35987252, -154.02482672],
	[113.7094765, 111.09906285],
	[-153.06934901, 42.92576387]],
	[[-9.07037063, 161.28361024],
	[-135.14051837, -88.49697651],
	[144.210889, -72.78663374]],
	[[-22.5465921, -162.54630949],
	[152.04252936, 61.74723322],
	[-129.49593726, 100.79907627]],
	[[54.29688426, 157.57419249],
	[-163.6116958, -31.76461513],
	[109.31481155, -125.80957736]],
	[[-84.94462652, -146.36756362],
	[169.23034164, -0.38042267],
	[-84.28571513, 146.74798629]],
	[[113.26023569, 129.17274088],
	[-168.49699292, 33.49987091],
	[55.23675723, -162.67261179]],
	[[-138.0695485, -106.47934878],
	[161.24859527, -66.3320621],
	[-23.17904677, 172.81141088]],
	[[158.3020698, 79.00778344],
	[-147.57378246, 97.5897222],
	[-10.72828735, -176.59750564]],
	[[-173.03637766, -47.68710104],
	[127.81642977, -126.01034831],
	[45.21994789, 173.69744936]],
	[[181.54078496, 13.62397491],
	[-102.56910085, 150.40694414],
	[-78.97168411, -164.03091905]],
	[[-183.30750352, 21.9362574],
	[72.65639559, -169.71708346],
	[110.65110794, 147.78082605]],
	[[178.078777, -57.65509173],
	[-39.10861441, 183.04829062],
	[-138.97016259, -125.3931989]],
	[[-165.86373639, 92.15123873],
	[3.12655446, -189.71782865],
	[162.73718193, 97.56658991]],
	[[146.94507611, -124.0539928],
	[33.96137115, 189.28516527],
	[-180.90644726, -65.23117247]],
	[[-121.87503688, 152.057528],
	[-70.74816365, -181.57564203],
	[192.62320053, 29.51811402]],
	[[91.4605988, -174.97397653],
	[105.80160928, 166.69419027],
	[-197.26220808, 8.27978626]],
	[[-56.7382183, 191.78314468],
	[-137.71996616, -145.02831075],
	[194.45818446, -46.75483393]],
];
